.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    width: 100%;
}

/* .titleContent {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.title {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    color: #fff;
}

.text {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #fff;
    width: 70%;
} */

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    max-width: 250px;
    width: 100%;
}

.selectContent{
    position: relative;
  display: inline-block;
    width: 100%;
}
.selectContent i {
    position: absolute;
    right: 5px;
    top: 0px;
    bottom: 0px;
    margin: auto;
    height: fit-content;
    font-size: 30px;
    cursor: pointer;
    color: #fff;
  }

.form input,
.selectContent select {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50px;
    text-align: center;

    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: center;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}


.form input::placeholder,
.selectContent select::placeholder {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #fff;
}

/* Chrome, Safari, Edge */
.form input[type="number"]::-webkit-outer-spin-button,
.form input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form input[type="number"] {
  -moz-appearance: textfield;
}


.selectContent select {
    cursor: pointer;
}

.selectContent select option {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #000;
    cursor: pointer;
}

.button {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    width: fit-content;
    color: #ec543c;
    background: #fcec1b;
    border: 1px solid #fcec1b;
    border-radius: 50px;
    cursor: pointer;
    transition: all .4s ease-in-out;
    margin: 10px auto;
    padding: 5px 25px;
}

.button:hover {
    background: transparent;
}

.buttonDisabled {
    pointer-events: none;
    opacity: 0.5;
    /* Opcional: para indicar visualmente que el botón está deshabilitado */
}


@media screen and (max-width:767px) {
    .container {
        width: 100%;
    }

    .title {
        font-size: 40px;
    }

    .form {
        width: 100%;
    }
}