.tableContainer {
  width: 100%;
  margin: 20px 0;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0px;
  margin-bottom: 20px;
}

.title {
  margin: 0px !important;
  font-family: 'Omnes', Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  text-decoration: underline;
}

.titleSub {
  font-family: 'Omnes', Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-decoration: underline;
}


.table {
  width: 100%;
  /*  border-collapse: collapse; */
}

th,
td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #212529;
  color: #fff;
}

td {
  font-size: 12px;
}

@media (max-width: 768px) {

  th,
  td {
    padding: 10px;
    font-size: 14px;
  }

  th {
    font-size: 15px;
  }
}