@import './fonts.css';

/* Resetting margin and padding for all elements */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Omnes', Arial, sans-serif;
  background-color: #f5f5f5;
}