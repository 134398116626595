.container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.fondoImg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: inherit;
  z-index: 2;
  max-width: 1330px;
  width: 90%;
  margin: 40px auto auto;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 50%;
}

.imgContainer a {
  width: 60%;
  display: flex;
  justify-content: center;
}

.image1 {
  width: 100%;
  height: auto;
}

.image2 {
  width: 100%;
  height: auto;
}

.boxContainer {
  max-width: 450px;
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 150px;
  margin: 0 0 auto 0;
}

.logoSuave {
  width: 200px;
  width: 40%;
  height: auto;
  margin: 0 0 0 auto;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 25px;
  width: 100%;
  margin-right: 100px;
}

.inputContent {
  max-width: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.inputContent input {
  width: 75%;
  padding: 8px;
  box-sizing: border-box;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50px;
  text-align: center;

  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
}


.inputContent input::placeholder {
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  color: #fff;
}

.link {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

.error {
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: rgb(163, 0, 0);
}

.linkRegister {
  text-decoration: underline;
  color: inherit;
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  color: #fff;
}

.button {
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 600;
  font-size: 28px;
  text-align: center;
  width: fit-content;
  color: #ec543c;
  background: #fcec1b;
  border: 1px solid #fcec1b;
  border-radius: 50px;
  cursor: pointer;
  transition: all .4s ease-in-out;
  margin: 0px auto;
  padding: 5px 25px;
}

.button:hover {
  background: transparent;
}

.buttonDisabled {
  pointer-events: none;
  opacity: 0.5;
  /* Opcional: para indicar visualmente que el botón está deshabilitado */
}

.logoSuave2 {
  width: 100%;
  height: auto;
}

.textFooter {
  margin: 0 auto;
  max-width: 1300px;
  width: 90%;
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  /* border: 1px solid red; */
  z-index: 2;
  text-align: justify;
  margin: auto 0 20px 0;
}

@media screen and (max-width:1439px) {
  .container {
    height: 100%;
    padding: 0px;
  }

  .content {
    margin-top: 40px;
  }

  .textFooter {
    margin-bottom: 20px;
  }

  .imgContainer {
    width: 55%;
  }
}

@media screen and (max-width:1023px) {
  .container {
    height: 100%;
  }

  .fondoImg {
    display: none;
  }

  .fondoImgMobile {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    z-index: 1;
  }

  .content {
    flex-direction: column;
    gap: 60px;
  }

  .logoSuave2 {
    width: 40%;
  }

  .imgContainer {
    width: 100%;
    gap: 60px;
  }

  .imgContainer a {
    width: 90%;
  }

  .image2 {
    width: 100%;
  }

  .boxContainer {
    width: 100%;
  }

  .formContainer {
    width: 100%;
  }

  .form {
    margin-right: 0px;
  }

  .inputContent input {
    width: 100%;
  }
}

@media screen and (max-width:767px) {
  .content {
    padding: 0px 0 0 0;
    gap: 40px;
  }

  .imgContainer {
    gap: 40px;
  }

  .columnContainer {
    flex-direction: column;
    gap: 30px;
  }

  .formColumn {
    width: 100%;
  }
}