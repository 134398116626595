.container {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
}

.fondoImg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}

.fondoImgMobile {
    display: none;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: inherit;
    z-index: 2;
    max-width: 1330px;
    width: 90%;
    margin: 0 auto;
}

.imgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 50%;
}

.image1 {
    width: 60%;
    height: auto;
}

.image2 {
    width: 100%;
    height: auto;
}

.boxContainer {
    width: 450px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 70px;
}

.logoSuave {
    width: 200px;
    width: 40%;
    height: auto;
    margin: 0 0 0 auto;
}

.infoContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    width: 440px;
    margin: 0 auto;
}

.titleContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
}

.title {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: bold;
    font-size: 60px;
    text-align: center;
    color: #fff;
}

.text {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #fff;
    width: 70%;
}

.textNegrita {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #fff;
}


.button {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    width: fit-content;
    color: #ec543c;
    background: #fcec1b;
    border: 1px solid #fcec1b;
    border-radius: 50px;
    cursor: pointer;
    transition: all .4s ease-in-out;
    margin: 10px auto;
    padding: 5px 25px;
}

.button:hover {
    background: transparent;
}

.logoSuave2 {
    width: 40%;
    height: auto;
}

.textFooter {
    margin: 0 auto;
    max-width: 1300px;
    width: 90%;
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 10px;
    text-align: left;
    color: #fff;
    /* border: 1px solid red; */
    z-index: 2;
}

.link {
    color: inherit;
    text-decoration: underline;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width:1439px) {
    .container {
        height: 100%;
        padding: 40px 0;
    }
}

@media screen and (max-width:1023px) {
    .container {
        height: 100%;
        padding: 40px 0;
    }

    .fondoImg {
        display: none;
    }

    .fondoImgMobile {
        display: flex;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        z-index: 1;
    }

    .content {
        flex-direction: column;
        align-items: center;
        margin: 0;
        padding: 0px 0 0 0;
        gap: 60px;
    }

    .imgContainer {
        width: 100%;
        gap: 60px;
    }

    .image1 {
        width: 90%;
    }

    .image2 {
        width: 100%;
    }
}

@media screen and (max-width:767px) {
    .content {
        padding: 0px 0 0 0;
        gap: 40px;
    }

    .imgContainer {
        gap: 40px;
    }

    .columnContainer {
        flex-direction: column;
        gap: 30px;
    }

    .infoContent {
        width: 100%;
    }

    .boxContainer {
        width: 100%;
    }

    .title {
        font-family: 'Omnes', Arial, sans-serif;
        font-weight: bold;
        font-size: 40px;
        text-align: center;
        color: #fff;
    }

    .text {
        width: 100%;
    }
}