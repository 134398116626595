.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modalContent {
  background-color: white;
  padding: 20px 20px 20px 20px;
  border-radius: 16px;
  position: relative;
  max-width: 500px;
  width: 100%;
  /* max-height: 50vh; */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}


.closeButton {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000;
  background: #fcec1b;
  border: 1px solid #fcec1b;
  border-radius: 50px;
  padding: 0 8px;
}
.closeButton:hover{
  color: #ec543c;
}

.title{
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: bold;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #000;
  margin: 10px 0;
}
.image{
  width: 100%;
  height: auto;

}
.parrafo{
  width: 100%;
  max-height:45vh;
  height: 100%;
  overflow-y: auto;
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: bold;
  font-weight: 400;
  line-height: 22px;
  font-size: 14px;
  
}
.parrafo::-webkit-scrollbar {
  display: none;  /* Para Chrome, Safari y Opera */
}

.acceptButton {
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  width: fit-content;
  color: #ec543c;
  background: #fcec1b;
  border: 2px solid #fcec1b;
  border-radius: 50px;
  cursor: pointer;
  transition: all .4s ease-in-out;
  margin: 25px auto 0px;
  padding: 5px 15px;
}

.acceptButton:hover {
  background: transparent;
}

@media screen and (max-width:1023px) {
  .modalContent{
    max-width: 350px;
  }
}
@media screen and (max-width:767px) {
  .modalContent{
    max-width: none;
    width: 90%;
  }
}