/* src/fonts.css */
@font-face {
    font-family: 'Omnes';
    src: url('./fonts/Omnes_Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Omnes';
    src: url('./fonts/Omnes-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  