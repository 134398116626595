.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 20px;
    margin: 50px auto 0;
    width: 581px;
}

.titleContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.title {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: bold;
    font-size: 50px;
    text-align: center;
    color: #fff;
}

.text {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #fff;
    
}
.textNegrita{
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #fff;
}

.button {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    width: fit-content;
    color: #ec543c;
    background: #fcec1b;
    border: 1px solid #fcec1b;
    border-radius: 50px;
    cursor: pointer;
    transition: all .4s ease-in-out;
    margin: 10px auto;
    padding: 5px 25px;
}

.button:hover {
    background: transparent;
}

.buttonDisabled {
    pointer-events: none;
    opacity: 0.5;
    /* Opcional: para indicar visualmente que el botón está deshabilitado */
}


@media screen and (max-width:767px) {
    .container {
        width: 100%;
        margin-top: 0px;
    }

    .title {
        font-size: 40px;
    }

    .form {
        width: 100%;
    }
    .text{
        font-size: 16px;
    }
}