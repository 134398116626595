.divAdmin{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}



.divAdmin button {
 margin-top: 20px;
 cursor: pointer;
}


table{
    width: 80%;
    height: 200px;
    border: 1px solid black;
    overflow: hidden;
}