.container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.fondoImg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.fondoImgMobile {
  display: none;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: inherit;
  z-index: 2;
  max-width: 1330px;
  width: 90%;
  margin: 30px auto auto;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 50%;
}

.imgContainer a {
  width: 60%;
}

.image1 {
  width: 100%;
  height: auto;
}

.image2 {
  width: 100%;
  height: auto;
}

.formContainer {
  max-width: 500px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoSuave {
  width: 200px;
  width: 40%;
  height: auto;
  margin: 0 0 0 auto;
}

.titleForm {
  font-family: "Omnes", Arial, sans-serif;
  font-weight: bold;
  font-size: 40px;
  text-align: center;
  color: #fff;
  text-decoration: underline;
  margin: 80px 0 10px;
}

.form {
  display: flex;
  flex-direction: column;
}

.columnContainer {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
}

.formColumn {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 50%;
  padding: 0px;
  box-sizing: border-box;
  color: #fff;
}

.inputContent,
.selectContent {
  position: relative;
}

.inputContent input,
.selectContent select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50px;

  font-family: "Omnes", Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

/* Chrome, Safari, Edge */
.inputContent input[type="number"]::-webkit-outer-spin-button,
.inputContent input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputContent input[type="number"] {
  -moz-appearance: textfield;
}


.inputContent input::placeholder,
.selectContent select::placeholder {
  font-family: "Omnes", Arial, sans-serif;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #fff;
}

.selectContent {
  position: relative;
  display: inline-block;
  width: 100%;
}

.selectContent select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.selectContent i {
  position: absolute;
  right: 5px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  height: fit-content;
  font-size: 30px;
  cursor: pointer;
}

.selectContent select option {
  font-family: "Omnes", Arial, sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #000;
}

.error {
  font-family: "Omnes", Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  text-align: left;
  color: rgb(163, 0, 0);
  position: absolute;
  left: 0px;
  bottom: -18px;
}

.parrafoTerminos {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Omnes", Arial, sans-serif;
  font-weight: 400;
  font-size: 11px;
  text-align: left;
}

.parrafoTerminos input {
  width: fit-content;
  cursor: pointer;
}

.link {
  color: inherit;
  text-decoration: underline;

  cursor: pointer;
}

.button {
  font-family: "Omnes", Arial, sans-serif;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  width: fit-content;
  color: #ec543c;
  background: #fcec1b;
  border: 1px solid #fcec1b;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  margin: 10px auto;
  padding: 5px 15px;
}

.button:hover {
  background: transparent;
}

.buttonDisabled {
  pointer-events: none;
  opacity: 0.5;
  /* Opcional: para indicar visualmente que el botón está deshabilitado */
}

.logoSuave2 {
  width: 100%;
  height: auto;
}

.textFooter {
  max-width: 1300px;
  width: 90%;
  font-family: "Omnes", Arial, sans-serif;
  font-weight: 400;
  font-size: 10px;
  color: #fff;
  margin: auto auto 20px;
  z-index: 2;
  text-align: justify;
}



.depaMobile {
  display: none;
}

.numDocuMobile {
  display: none;
}


/* .depaDesk {} */

/* .numDocuDesktop {} */


@media screen and (max-width: 1439px) {
  .container {
    height: 100%;
    padding: 0px;
  }

  .content {
    margin-top: 40px;
  }
}

@media screen and (max-width: 1023px) {
  .container {
    height: 100%;
  }

  .fondoImg {
    display: none;
  }

  .fondoImgMobile {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    z-index: 1;
  }

  .content {
    flex-direction: column;
    align-items: center;
    padding: 0px 0 0 0;
    gap: 60px;
  }

  .logoSuave2 {
    width: 40%;
  }

  .imgContainer {
    width: 100%;
    gap: 60px;
  }

  .imgContainer a {
    width: 90%;
  }

  .image1 {
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  .image2 {
    width: 100%;
  }

  .titleForm {
    margin: 0;
  }

  .formContainer {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .content {
    padding: 0px 0 0 0;
    gap: 40px;
  }

  .imgContainer {
    gap: 40px;
  }

  .columnContainer {
    flex-direction: column;
    gap: 30px;
  }

  .formColumn {
    width: 100%;
  }

  .depaDesk {
    display: none;
  }

  .deskDocu {
    display: none;
  }

  .depaMobile {
    display: flex;
  }

  .mobileDocu {
    display: flex;
  }






  .depaMobile {
    display: flex;
  }

  .numDocuMobile {
    display: flex;
  }


  .depaDesk {

    display: none;
  }

  .numDocuDesktop {
    display: none;
  }


}