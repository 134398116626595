.container {
    display: flex;
    flex-direction: column;
}

.title {
    font-family: 'Omnes', Arial, sans-serif;
    font-size: 16px;
    font-weight: 500;
    margin: 10px 0 5px 0;
}
.container p {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
}
.container ul li,
.container span {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 14px;
}