.container {
    width: 100%;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.form input {
    max-width: 250px;
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    background: transparent;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 50px;
    text-align: center;

    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
}

.form input::placeholder {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    color: #fff;
}

/* Chrome, Safari, Edge */
.form input[type="number"]::-webkit-outer-spin-button,
.form input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.form input[type="number"] {
  -moz-appearance: textfield;
}

.button {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    width: fit-content;
    color: #ec543c;
    background: #fcec1b;
    border: 1px solid #fcec1b;
    border-radius: 50px;
    cursor: pointer;
    transition: all .4s ease-in-out;
    margin: 10px auto;
    padding: 5px 25px;
}

.button:hover {
    background: transparent;
}

.buttonDisabled {
    pointer-events: none;
    opacity: 0.5;
    /* Opcional: para indicar visualmente que el botón está deshabilitado */
}