.container {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.fondoImg {
    position: absolute;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    object-position: center;
    z-index: 1;
}

.content {
    position: inherit;
    max-width: 1300px;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin: auto;
    margin-top: 30px;
    z-index: 2;
}

.block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 30px;
}

.parrafoTerminos {
    position: inherit;
    z-index: 2;
    max-width: 1300px;
    width: 90%;
    margin: 0 auto 20px auto;
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 10px;
    text-align: justify;
    color: #fff;
}

.imgContainer {
    display: flex;
    flex-direction: column;
    width: 69%;
}

.image1 {
    width: 60%;
    height: auto;
    margin: 0 auto;
}

.image1 {
    width: 80%;
    height: auto;

}

.boxContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;

}

.image3 {
    width: 150px;
    height: auto;
    margin: 0 0 0 auto;

}

.boxs {
    max-width: 310px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 16px;
    border: 1px solid #fff;
    padding: 20px 30px;
    margin-right: 50px;
}

.linea {
    width: 100%;
    height: 2px;
    background: #fff;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #fff;
}

.box h4 {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: bold;
    font-size: 40px;
}

.box p {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
}

.box a {
    width: 100%;
}

.box button {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: #ec543c;
    background: #fcec1b;
    border: 1px solid #fcec1b;
    border-radius: 50px;
    cursor: pointer;
    transition: all .4s ease-in-out;
    padding: 5px 15px;
    margin: 10px 0;
    width: 100%;
}

.box button:hover {
    background: transparent;
}

.box span {
    font-family: 'Omnes', Arial, sans-serif;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
}

.link {
    color: inherit;
    text-decoration: underline;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width:1439px) {
    .container {
        height: 100%;
        overflow-y: auto;
    }

    .fondoImg {
        height: 100%;
    }

    .content {
        margin-top: 30px;
    }

    .parrafoTerminos {
        margin-top: 30px;
    }

    .box h4 {
        font-size: 30px;
    }

    .box p {
        font-size: 22px;
    }
}

@media screen and (max-width:1023px) {
    .container {
        height: 100%;
        background: #000000;
        padding: 0px;
        gap: 40px;
    }

    .fondoImgMobile {
        display: flex;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        position: absolute;
        z-index: 1;
    }

    .content {
        position: inherit;
        z-index: 2;
        max-width: 700px;
        margin-top: 50px;
    }

    .block {
        flex-direction: column;
        gap: 60px;
    }

    .imgContainer {
        width: 100%;
        gap: 50px;
    }

    .image1 {
        width: 100%;
    }

    .boxContainer {
        width: 100%;
        position: inherit;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        margin: 0px;
    }


    .boxs {
        margin-right: 0px;
    }

    .image3 {
        position: inherit;
        margin: 0px;
        width: 50%;
    }

    .parrafoTerminos {
        max-width: 700px;
        width: 100%;
        background: #000000;
        margin: 0;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:767px) {
    .container {
        gap: 40px;
    }

    .content {
        margin-top: 40px;
    }

    .block {
        gap: 40px;
    }

    .imgContainer {
        gap: 40px;
    }

    .parrafoTerminos {
        width: 90%;
    }
}