.container {
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.fondoImg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: 1;
}

.fondoImgMobile {
  display: none;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  position: inherit;
  z-index: 2;
  max-width: 1330px;
  width: 90%;
  margin: 40px auto 0;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 50%;
}

.imgContainer a {
  width: 60%;

}

.image1 {
  width: 100%;
  height: auto;
}

.image2 {
  width: 100%;
  height: auto;
}

.boxContainer {
  /* max-width: 650px;
  width: 35%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: 100px; */
}

.logoSuave {
  max-width: 180px;
  width: 40%;
  height: auto;
  margin: 0 0 0 auto;
}

.infoContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  margin: 60px auto 0;
}

.title {
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: bold;
  font-size: 70px;
  text-align: center;
  color: #fff;
}

.text {
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  color: #fff;
  max-width: 327px;
  width: 80%;
}

.inputContent {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  width: 100%;
  gap: 10px;
  position: relative;
}

.inputContent input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50px;
  text-align: center;

  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
}


.inputContent input::placeholder {
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  color: #fff;
}

.inputContent i {
  font-size: 30px;
  color: #fff;
  position: absolute;
  right: -40px;
  top: 0px;
  bottom: 0px;
  margin: auto;
  z-index: 10;
  height: fit-content;
  cursor: pointer;
}

.button {
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 600;
  font-size: 30px;
  text-align: center;
  width: fit-content;
  color: #ec543c;
  background: #fcec1b;
  border: 1px solid #fcec1b;
  border-radius: 50px;
  cursor: pointer;
  transition: all .4s ease-in-out;
  margin: 10px auto;
  padding: 5px 25px;
}

.button:hover {
  background: transparent;
}

.logoSuave2 {
  width: 40%;
  height: auto;
}

.errorMenssage {
  position: absolute;
  bottom: -20px;
  color: #fff;
}

.textFooter {
  margin: 0 auto;
  max-width: 1300px;
  width: 90%;
  font-family: 'Omnes', Arial, sans-serif;
  font-weight: 400;
  font-size: 10px;
  text-align: left;
  color: #fff;
  /* border: 1px solid red; */
  z-index: 2;
  margin: auto 0 20px 0;
}
.link {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}

@media screen and (max-width:1439px) {
  .container {
    height: 100%;
    padding: 0px;
  }

  .content {
    margin-top: 40px;
  }

  .imgContainer {
    width: 55%;
  }

  .textFooter {
    margin-bottom: 20px;
  }
}

@media screen and (max-width:1023px) {
  .container {
    height: 100%;
  }

  .fondoImg {
    display: none;
  }

  .fondoImgMobile {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    z-index: 1;
  }

  .content {
    flex-direction: column;
    align-items: center;
    padding: 0px 0 0 0;
    gap: 50px;
  }

  .imgContainer {
    width: 100%;
    gap: 60px;
  }

  .imgContainer a {
    width: 90%;
  }

  .image2 {
    width: 100%;
  }

  .titleForm {
    margin: 0;
  }

  .formContainer {
    width: 100%;
  }

  .boxContainer {
    width: 100%;
  }

  .infoContent {
    margin: 0px 0;
  }
}

@media screen and (max-width:767px) {
  .content {
    padding: 0px 0 0 0;
    gap: 20px;
  }

  .imgContainer {
    gap: 40px;
  }

  .columnContainer {
    flex-direction: column;
    gap: 30px;
  }

  .formColumn {
    width: 100%;
  }

  .title {
    font-size: 40px;
  }

  .text {
    width: 100%;
  }

  .inputContent {
    width: 90%;
    margin: 0 auto 0 0;
  }

  .inputContent input {
    width: 100%;
  }
}